import { ReactElement, createElement } from 'react';

export const nl2br = (text: string): (string | ReactElement)[] => {
  const newlineRegex = /(\r\n|\r|\n)/g;

  let key = 0;
  return text.split(newlineRegex).map((line) => {
    key += 1;
    if (line.match(newlineRegex)) {
      return createElement('br', { key: `br-${key}` });
    }

    return line;
  });
};

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  let os = 'Other';

  if (/android/i.test(ua)) {
    os = 'Android';
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    os = 'iOS';
  }

  return 'iOS';
};
